<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-delete @deleteRecord="clearStatistics"></app-button-delete>
    </template>
    <template slot="body">
      <app-performance-filter></app-performance-filter>
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                Max memory today
              </h3>
              <app-data-table
                :data="maxMemory.data"
                :totalCount="maxMemory.totalCount"
                :config="maxMemoryConfig"
                :page="maxMemoryConfig.page"
                :limit="pageLimit"
                @page-change="setPageAndGetMaxMemory"
                @detail-click="showInfoModalMem"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                Max duration today
              </h3>
              <app-data-table
                :data="maxDuration.data"
                :totalCount="maxDuration.totalCount"
                :config="maxDurationConfig"
                :page="maxDurationConfig.page"
                :limit="pageLimit"
                @page-change="setPageAndGetMaxDuration"
                @detail-click="showInfoModalDur"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                Avg memory today
              </h3>
              <app-data-table
                :data="avgMemory.data"
                :totalCount="avgMemory.totalCount"
                :config="avgMemoryConfig"
                :page="avgMemoryConfig.page"
                :limit="pageLimit"
                @page-change="setPageAndGetAvgMemory"
                @detail-click="showInfoModalMem"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                Avg duration today
              </h3>
              <app-data-table
                :data="avgDuration.data"
                :totalCount="avgDuration.totalCount"
                :config="avgDurationConfig"
                :page="avgDurationConfig.page"
                :limit="pageLimit"
                @page-change="setPageAndGetAvgDuration"
                @detail-click="showInfoModalDur"
              />
            </div>
          </div>
        </div>
      </div>
      <app-preloader v-if="loading"/>
      <app-info-modal
        v-if="infoModal"
        :item="infoModalItem"
        @close="closeInfoModal"
      />
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import AppPerformanceFilterView from '../../components/filter/FilterAppPerformance'
import Preloader from '../../components/preloader/Preloader'
import DataTable from '../../components/shared/DataTable'
import InfoModal from '../../components/modal/ModalInfo'
import ButtonDelete from '../../components/shared/ButtonDelete'
import { LIMIT as PAGE_LIMIT } from '../../store/modules/appPerformanceStore'

export default {
  name: 'AppPerformanceView',
  data () {
    return {
      loading: true,
      infoModal: false,
      infoModalItem: null,
      maxMemoryConfig: {
        page: 1,
        fields: {
          resource: 'Resource',
          'max.memory': 'Max',
          'avg.memory': 'Avg',
          hits: 'Hits',
          'maxMemDetail.eventTime': 'Time'
        },
        render: {
          'max.memory': (memory) => memory.toFixed(2) + ' MB',
          'avg.memory': (memory) => memory.toFixed(2) + ' MB',
          'maxMemDetail.eventTime': (time) => this.$options.filters.prettyTime(time)
        },
        actions: {
          event: [
            {
              event: 'detail-click',
              icon: 'fa-info'
            }
          ]
        }
      },
      maxDurationConfig: {
        page: 1,
        fields: {
          resource: 'Resource',
          'max.duration': 'Max',
          'avg.duration': 'Avg',
          hits: 'Hits',
          'maxDurDetail.eventTime': 'Time'
        },
        render: {
          'max.duration': (duration) => duration.toFixed(2) + ' s',
          'avg.duration': (duration) => duration.toFixed(2) + ' s',
          'maxDurDetail.eventTime': (time) => this.$options.filters.prettyTime(time)
        },
        actions: {
          event: [
            {
              event: 'detail-click',
              icon: 'fa-info'
            }
          ]
        }
      },
      avgMemoryConfig: {
        page: 1,
        fields: {
          resource: 'Resource',
          'avg.memory': 'Avg',
          'max.memory': 'Max',
          hits: 'Hits',
          'maxMemDetail.eventTime': 'Max Time'
        },
        render: {
          'max.memory': (memory) => memory.toFixed(2) + ' MB',
          'avg.memory': (memory) => memory.toFixed(2) + ' MB',
          'maxMemDetail.eventTime': (time) => this.$options.filters.prettyTime(time)
        },
        actions: {
          event: [
            {
              event: 'detail-click',
              icon: 'fa-info'
            }
          ]
        }
      },
      avgDurationConfig: {
        page: 1,
        fields: {
          resource: 'Resource',
          'avg.duration': 'Avg',
          'max.duration': 'Max',
          hits: 'Hits',
          'maxDurDetail.eventTime': 'Max Time'
        },
        render: {
          'avg.duration': (duration) => duration.toFixed(2) + ' s',
          'max.duration': (duration) => duration.toFixed(2) + ' s',
          'maxDurDetail.eventTime': (time) => this.$options.filters.prettyTime(time)
        },
        actions: {
          event: [
            {
              event: 'detail-click',
              icon: 'fa-info'
            }
          ]
        }
      }
    }
  },
  computed: {
    maxMemory () {
      return this.$store.getters['appPerformance/maxMemory']
    },
    maxDuration () {
      return this.$store.getters['appPerformance/maxDuration']
    },
    avgMemory () {
      return this.$store.getters['appPerformance/avgMemory']
    },
    avgDuration () {
      return this.$store.getters['appPerformance/avgDuration']
    },
    pageLimit () {
      return PAGE_LIMIT
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appDataTable: DataTable,
    appInfoModal: InfoModal,
    appButtonDelete: ButtonDelete,
    appPerformanceFilter: AppPerformanceFilterView
  },
  methods: {
    getAppPerformance () {
      this.$store.dispatch('appPerformance/fetch', 1)
        .then(() => {
          this.loading = false
        })
    },
    showInfoModalDur (item) {
      this.infoModalItem = item.maxDurDetail
      this.infoModal = true
    },
    showInfoModalMem (item) {
      this.infoModalItem = item.maxMemDetail
      this.infoModal = true
    },
    closeInfoModal () {
      this.infoModal = false
    },
    async clearStatistics () {
      await this.$store.dispatch('appPerformance/clear')
      this.$router.go()
    },
    setPageAndGetMaxMemory (page) {
      this.maxMemoryConfig.page = page
      this.$store.dispatch('appPerformance/fetchMaxMemory', page)
    },
    setPageAndGetMaxDuration (page) {
      this.maxDurationConfig.page = page
      this.$store.dispatch('appPerformance/fetchMaxDuration', page)
    },
    setPageAndGetAvgMemory (page) {
      this.avgMemoryConfig.page = page
      this.$store.dispatch('appPerformance/fetchAvgMemory', page)
    },
    setPageAndGetAvgDuration (page) {
      this.avgDurationConfig.page = page
      this.$store.dispatch('appPerformance/fetchAvgDuration', page)
    }
  },
  mounted () {
    this.getAppPerformance()
  }
}
</script>
